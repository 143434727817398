//Theme Base Colour
$theme-color: 'purple';

// Colours (All themes - but used in this settings file so need them here)
$color-verylightgrey: 			#fafafa; // Tile bg color
$color-brownish-grey: 			#717067; // text default			
$color-greyish-brown:			#3d3935; //sidebar text
$color-white: 					#fff; 

// Colours (maternity specific)
$color-purple: 					#733c97; //button purple, primary
$color-darkpurple:				#572772; //tiles arrow bg AKA Grape heading colour
$color-lightpink: 				#f087a6; //banner light
$color-darkpink: 				#a182bc; //banner dark 
$color-otherpink:				#c785b2; //sidebar background
$color-wisteria: 				#aa8fc3; //stats text (pinkypurple)

$color-primary: $color-purple; 
$color-secondary: $color-darkpurple; 
$color-tertiary: $color-darkpurple;

$selection-color: $color-primary;
$selection-text-color: #fff;

//typography
$font-color: $color-brownish-grey;
$heading-color: $color-darkpurple;
$highlight-color: $selection-color;
$highlight-text-color: $selection-text-color;
$link-color: $color-primary;
$link-color-hover: $color-secondary;

//backgrounds
$color-pagination-bg: $color-primary;
$color-sidebar-bg: $color-otherpink;

//gradients
$gradient-start: $color-lightpink;
$gradient-end: $color-darkpink;
$gradient-hr-bg: linear-gradient(to right, $gradient-start, $gradient-end);

//block specific
$color-stats: $color-wisteria;
$color-tile-arrowbg: $color-secondary;
$stat-counter-bg-image: url('/public/img/jpg/counters-maternity-bg@2x.jpg');
$stat-counter-bg-position-desktop: 100% 50%;

$color-search-bar: $color-secondary;

$sidebar-triangle-background: url('/public/img/png/ic/triangle-grape.png');

//Mobile
// --Menu
$mobile-menu-toggle-arrow-bg-image: url('/public/img/svg/ic/expand-maternity.svg'); 
$mobile-menu-toggler-bg: $color-secondary;
//lvl1
$mobile-menu-lvl1-bg: $color-primary;
$mobile-menu-lvl1-bg-active: $color-secondary; 
//lvl2
$mobile-menu-item-lvl2: $color-greyish-brown;
//lvl3
$mobile-menu-item-lvl3: $color-greyish-brown;

//Footer Link Hover (with this theme, the purple hover doesn't work on the dark bg)
$link-on-dark-hover: darken($color-verylightgrey, 15%);
$footer-social-link-hover: darken($color-verylightgrey, 18%);
